import React from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'

import Section from '../../Common/Section'

import {
  ContainerCell,
  ContainerDescription,
  Content,
  Description,
  Image,
} from './HeaderCell.styled'
import ButtonStyled from '../../Common/Button.styled'

const HeaderCell = ({ title, slug, description, publishDate, heroImage }) => {
  return (
    <ContainerCell>
      <Image fluid={heroImage.fluid} alt="Article preview" />
      <ContainerDescription>
        <Section>
          <Content>
            <Description>
              <h2>{title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: description.childMarkdownRemark.html,
                }}
              />
            </Description>
            <div style={{ textAlign: 'center' }}>
              <span
                style={{
                  display: 'block',
                  margin: '1rem 0',
                  fontWeight: '100',
                }}
              >
                {publishDate}
              </span>
              <ButtonStyled onClick={() => navigate(`/noticias/${slug}`)}>
                Leer más
              </ButtonStyled>
            </div>
          </Content>
        </Section>
      </ContainerDescription>
    </ContainerCell>
  )
}

HeaderCell.defaultProps = {
  title: 'Título de la notica',
  description: null,
  publishDate: 'Fecha',
  heroImage: null,
}

HeaderCell.propTypes = {
  title: PropTypes.string,
  description: PropTypes.object,
  heroImage: PropTypes.object,
  publishDate: PropTypes.string,
}

export default HeaderCell
