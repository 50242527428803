import styled from 'styled-components'
import Img from 'gatsby-image'
import { BP_MEDIUM } from '../../../styles/theme'

const ContainerCell = styled.div`
  min-height: 600px;
  height: 600px;
  min-width: 100%;
  position: relative;
  &:after {
    display: block;
    position: relative;
    background-image: linear-gradient(to bottom, transparent, #000);
    margin-top: -450px;
    height: 450px;
    width: 100%;
    content: '';
  }
`
const ContainerDescription = styled.div`
  position: absolute;
  width: 100%;
  bottom: 25px;
  z-index: 10;
  font-size: 1.5rem;
  color: ${props => props.theme.textLight};
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: ${BP_MEDIUM}) {
    flex-direction: column-reverse;
  }
`

const Description = styled.div`
  max-width: 700px;
  & > h2 {
    margin: 0.35rem 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  & > div > p {
    &:first-child {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    display: none;
  }
`

const Image = styled(Img)`
  width: 100%;
  height: 600px;
`

export { ContainerCell, ContainerDescription, Content, Description, Image }
