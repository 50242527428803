import React from 'react'
import _ from 'lodash'
import { StaticQuery, graphql } from 'gatsby'

import Carousel from '../Carousel'
import { HeaderCell } from '../Carousel/CarouselCells'

const SectionHeader = () => (
  <StaticQuery
    query={lastNewsQuery}
    render={data => {
      const lastNews = _.get(data, 'allContentfulNews.edges')
      return (
        <Carousel>
          {lastNews.map(({ node }) => (
            <div key={node.slug}>
              <HeaderCell {...node} />
            </div>
          ))}
        </Carousel>
      )
    }}
  />
)

export default SectionHeader

const lastNewsQuery = graphql`
  query LastNewsQuery {
    allContentfulNews(sort: { fields: [publishDate], order: DESC }, limit: 4) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "D/MM/YY")
          heroImage {
            fluid(maxWidth: 2100, maxHeight: 1176) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
