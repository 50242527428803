import React from 'react'
import { navigate } from 'gatsby'
import Section from '../Common/Section'

import {
  ContainerCardsNews,
  CardCellNews,
  CardDescription,
  CardImage,
  MainSectionContainer,
  BannerImg,
} from './SectionHomeNews.styled'
import Pagination from '../Pagination'

const SectionHomeNews = ({ news, page, limit, total, location, path }) => (
  <Section minHeight="100vh">
    <MainSectionContainer>
      <div style={{ flex: 1 }}>
        <ContainerCardsNews>
          {!news ? (
            <div style={{ minHeight: '400px' }}>No hay resultados</div>
          ) : (
            news.map(({ node }, index) => (
              <CardCellNews
                key={node.slug}
                active={index}
                onClick={() => navigate(`/noticias/${node.slug}`)}
              >
                <CardImage alt={node.title} fluid={node.heroImage.fluid} />
                <CardDescription>
                  <h3>{node.title}</h3>
                  <span>{node.publishDate}</span>
                </CardDescription>
              </CardCellNews>
            ))
          )}
        </ContainerCardsNews>
      </div>
      {path === '/contabilidad' || path === '/impuestos' || path === '/' ? (
        <div style={{ marginTop: 32 }}>
          <a href="https://www.ascg.mx/" target="_blank" rel="noreferrer">
            <BannerImg
              src={
                path === '/contabilidad' || path === '/impuestos'
                  ? require('../../images/banner2.png')
                  : require('../../images/banner1.png')
              }
              alt="Banner 1"
            />
          </a>
        </div>
      ) : null}
    </MainSectionContainer>

    <Pagination
      current={page}
      pageSize={limit}
      total={total}
      location={location}
    />
  </Section>
)

export default SectionHomeNews
