import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import { SectionHeader } from '../components/Sections'
import Categories from '../components/Categories'
import SectionHomeNews from '../components/Sections/SectionHomeNews'
import SEO from '../components/seo'

const Home = props => {
  console.log('props', props)
  const news = _.get(props, 'data.allContentfulNews.edges')
  return (
    <Layout location={props.location}>
      <SEO
        title="AS News firma corresponsal de AS Consulting Group."
        description="Enterate de todo lo que necesitas saber para emprender tu negocio, el blog de noticias que te ayudara a conocer más de México en temas contable, fiscal, legal, administrativos, entre otros, mantente informado con nostros y comenta."
        url={props.location.href}
      />
      <SectionHeader />
      <Categories filterCategories={props.pageContext.categories} />
      <SectionHomeNews
        news={news}
        {...props.pageContext}
        location={props.location}
        path={props.path}
      />
    </Layout>
  )
}

export default Home

export const NewsQuery = graphql`
  query NewsFilterList($categories: [String], $skip: Int!, $limit: Int!) {
    allContentfulNews(
      sort: { fields: [publishDate], order: DESC }
      filter: { categories: { elemMatch: { slug: { in: $categories } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "D/MM/YY")
          heroImage {
            fluid(maxWidth: 1200, maxHeight: 672) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
